import React from "react";

const ServiceList = [
	{
		title: "Baudienstleistungen",
		description:
			"Wir bieten eine breite Palette von Baudienstleistungen an, um Ihre Bedürfnisse zu erfüllen.",
	},
	{
		title: "Hochbau",
		description:
			"Wir realisieren Bauprojekte mit höchster Präzision und Qualität, von Sanierung bis zu Neubauten.",
		url: "/hochbau",
	},

	{
		title: "Tiefbau",
		description:
			"Entdecken Sie unsere Expertise im Tiefbau und erfahren Sie, wie wir Ihre Projekte effizient und zuverlässig umsetzen.",
		url: "/tiefbau",
	},
	{
		title: "Kundenbetreuung",
		description:
			"Wir stehen Ihnen während des gesamten Projekts zur Seite, um sicherzustellen, dass Ihre Bedürfnisse erfüllt werden.",
		url: "/kontakt",
	},
];

export const Services = () => {
	return (
		<div className="service-area ptb--80  grey-bg">
			<div className="container ">
				<div className="row ">
					<div className="col-lg-4 col-12 ">
						<div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
							<h2 className="title">Leistungen</h2>
							<p>
								Unser Unternehmen bietet umfassende und professionelle Dienstleistungen im
								Bereich Hoch- und Tiefbau an. Mit langjähriger Erfahrung und einem
								eingespielten Team aus Fachkräften sind wir Ihr zuverlässiger Partner für
								Bauprojekte aller Art. Unser Leistungsspektrum umfasst folgende Bereiche:
							</p>
						</div>
					</div>
					<div className="col-lg-8 col-12 mt_md--50">
						<div className="row service-one-wrapper pt--60">
							{ServiceList.map((val, i) => (
								<div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
									{val.url ? (
										<a href={val.url}>
											<div className="service service__style--2">
												<div className="content">
													<h3 className="title">{val.title}</h3>
													<p>{val.description}</p>
												</div>
											</div>
										</a>
									) : (
										<div className="service service__style--2">
											<div className="content">
												<h3 className="title">{val.title}</h3>
												<p>{val.description}</p>
											</div>
										</div>
									)}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Services;
