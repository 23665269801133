import {
	StartPage,
	AboutPage,
	HochbauPage,
	TiefbauPage,
	DetailsPage,
	KontaktPage,
	JobsPage,
	FAQsPage,
	ImpressumPage,
	DatenschutzPage,
	ErrorPage,
} from "../pages";

export const NavList = [
	{
		path: "/",
		component: StartPage,
		linkText: "Start",
	},
	{
		path: "/about",
		component: AboutPage,
		linkText: "Über uns",
	},
	{
		path: "/hochbau",
		component: HochbauPage,
		linkText: "Hochbau",
	},
	{
		path: "/tiefbau",
		component: TiefbauPage,
		linkText: "Tiefbau",
	},
	{
		path: "/jobs",
		component: JobsPage,
		linkText: "Jobs",
	},

	{
		path: "/kontakt",
		component: KontaktPage,
		linkText: "Kontakt",
	},
];

export const FooterNavList = [
	{
		path: "/faqs",
		component: FAQsPage,
		linkText: "FAQs",
	},
	{
		path: "/impressum",
		component: ImpressumPage,
		linkText: "Impressum",
	},
	{
		path: "/datenschutz",
		component: DatenschutzPage,
		linkText: "Datenschutz",
	},
];

export const AdditonalRoutes = [
	{
		path: "/details",
		component: DetailsPage,
	},
	{
		path: "*",
		component: ErrorPage,
	},
];

export const BusinessInformations = {
	Unternehmen: "ECO² Building",
	Strasse: "Kennedyallee 93",
	PLZ: "60596",
	Ort: "Frankfurt am Main",
	Telefon: "069/26024889",
	Email: "info@eco2-building.de",
	steuerNr: "folgt..",
	UstIdNr: "folgt...",
	Handelsregister: "131355",
	Registergericht: "Frankfurt am Main",
	Geschäftsführer: "Zakaria El Gouy und Tamim Mahbub",
	VertretungDurch: "Zakaria El Gouy und Tamim Mahbub",
	Entwicklung: "ilkerrr.de",
	Design: "ilkerrr.de",
};

export const jobs = [
	{
		id: 1234,
		title: "Einkäufer für Nachunternehmerleistungen",
		company: "Eco 2 Building",
		description:
			"Eco 2 Building sucht einen Einkäufer für Nachunternehmerleistungen, der die Beschaffung von Bauleistungen steuert und sicherstellt, dass alle Aufträge termingerecht und innerhalb des Budgets vergeben werden. In enger Zusammenarbeit mit unseren Projektteams tragen Sie maßgeblich zur erfolgreichen Projektabwicklung bei.",
		capacity: "Vollzeit",
		location: "Frankfurt am Main",
		releaseDate: new Date("2024-10-01"),
		qualifications: [
			"Abgeschlossenes Studium im Bauingenieurwesen oder eine vergleichbare technische Qualifikation",
			"Erfahrung im Einkauf von Bauleistungen",
			"Strukturierte Arbeitsweise und Teamfähigkeit",
			"Ausgeprägtes Verhandlungsgeschick und Durchsetzungsvermögen",
		],
		requirements: [
			"Koordination und Verhandlung mit Nachunternehmern",
			"Kostenermittlung und Ausschreibungen",
			"Überwachung der Lieferfristen",
		],
	},
	{
		id: 1,
		title: "Facharbeiter Bau / Betonbauer / Sanierer / Tiefbau",
		company: "Eco 2 Building",
		description:
			"Für unser wachsendes Team suchen wir qualifizierte Facharbeiter in den Bereichen Hochbau, Betonbau, Sanierung und Tiefbau. Sie arbeiten auf unseren Baustellen und führen verschiedene Bauarbeiten aus.",
		capacity: "Vollzeit",
		location: "Frankfurt am Main",
		releaseDate: new Date("2024-09-20"),
		qualifications: [
			"Abgeschlossene Ausbildung im Baugewerbe",
			"Berufserfahrung im Hoch- oder Tiefbau",
			"Zuverlässigkeit und Teamfähigkeit",
		],
		requirements: [
			"Durchführung von Bauarbeiten nach Plan",
			"Arbeiten im Team auf Baustellen",
			"Einhaltung von Sicherheitsvorschriften",
		],
	},
	{
		id: 2,
		title: "Bauingenieur als Bauleitung",
		company: "Eco 2 Building",
		description:
			"Als Bauingenieur in der Bauleitung übernehmen Sie die Planung und Koordination unserer Bauprojekte. Sie sind verantwortlich für die technische Umsetzung und das Management der Baustellen.",
		capacity: "Vollzeit",
		location: "Frankfurt am Main",
		releaseDate: new Date("2024-09-30"),
		qualifications: [
			"Abgeschlossenes Studium im Bauingenieurwesen",
			"Erfahrung in der Bauleitung",
			"Kenntnisse in Bauplanung und -recht",
		],
		requirements: [
			"Koordination der Bauabläufe",
			"Überwachung der Bauausführung",
			"Sicherstellung der Einhaltung von Zeitplänen und Budgets",
		],
	},
	{
		id: 3,
		title: "Sekretariat / Allrounder (Buchhaltung, Finanzen etc.)",
		company: "Eco 2 Building",
		description:
			"Wir suchen eine Allroundkraft im Sekretariat, die in allen Bereichen unterstützt – von der Buchhaltung über das Büro-Management bis hin zu Finanzangelegenheiten. Sie arbeiten eng mit der Geschäftsführung zusammen und helfen, den Betrieb reibungslos zu gestalten.",
		capacity: "Teilzeit",
		location: "Frankfurt am Main",
		releaseDate: new Date("2024-10-05"),
		qualifications: [
			"Erfahrung im Sekretariats- oder Finanzbereich",
			"Gute organisatorische Fähigkeiten",
			"Kenntnisse in der Buchhaltung",
		],
		requirements: [
			"Buchführung und Verwaltung der Finanzen",
			"Organisation des Bürobetriebs",
			"Administrative Unterstützung der Geschäftsführung",
		],
	},
	{
		id: 4,
		title: "Energieberater",
		company: "Eco 2 Building",
		description:
			"Als Energieberater beraten Sie unsere Kunden in allen Fragen der Energieeffizienz und nachhaltigen Bauweise. Sie führen Energieaudits durch und geben Empfehlungen zur Optimierung von Gebäuden.",
		capacity: "Teilzeit",
		location: "Frankfurt am Main",
		releaseDate: new Date("2024-09-25"),
		qualifications: [
			"Abgeschlossene Ausbildung oder Studium im Bereich Energieberatung",
			"Erfahrung in der Energieberatung oder im Bauwesen",
			"Kenntnisse über energetische Sanierung und Bauvorschriften",
		],
		requirements: [
			"Durchführung von Energieaudits",
			"Beratung zu Energieeinsparungen und Fördermitteln",
			"Erstellung von Gutachten und Berichten",
		],
	},
	{
		id: 5,
		title: "Werkstudenten",
		company: "Eco 2 Building",
		description:
			"Wir suchen engagierte Werkstudenten, die unser Team bei laufenden Bauprojekten unterstützen. Dies ist eine großartige Gelegenheit, praktische Erfahrungen zu sammeln und Ihre Kenntnisse im Bauwesen zu erweitern.",
		capacity: "Teilzeit",
		location: "Frankfurt am Main",
		releaseDate: new Date("2024-10-10"),
		qualifications: [
			"Studium im Bereich Bauingenieurwesen oder verwandten Bereichen",
			"Gutes technisches Verständnis",
			"Teamfähigkeit und Lernbereitschaft",
		],
		requirements: [
			"Mitarbeit bei laufenden Bauprojekten",
			"Unterstützung der Bauleitung",
			"Dokumentation und Berichtswesen",
		],
	},
	{
		id: 6,
		title: "Praktikanten",
		company: "Eco 2 Building",
		description:
			"Wir bieten Praktikumsplätze für motivierte Studierende, die Einblicke in die Bauwirtschaft und Bauprozesse gewinnen möchten. Sie haben die Möglichkeit, verschiedene Bereiche unseres Unternehmens kennenzulernen.",
		capacity: "Vollzeit/Teilzeit",
		location: "Frankfurt am Main",
		releaseDate: new Date("2024-10-15"),
		qualifications: [
			"Studium oder Ausbildung im Bauwesen oder verwandten Bereichen",
			"Interesse an der Bauindustrie",
			"Selbstständiges Arbeiten und Teamfähigkeit",
		],
		requirements: [
			"Unterstützung bei verschiedenen Bauprojekten",
			"Mitarbeit in der Bauplanung und -ausführung",
			"Erstellung von Berichten und Dokumentationen",
		],
	},
];
