import React, { Fragment } from "react";
import { PageHelmet, Breadcrumb } from "../../component";
import { FAQsAccordion, faqPhotovoltaik, faqLuftwaermepumpe } from "./partials";

export const FAQsPage = () => {
	return (
		<Fragment>
			<PageHelmet pageTitle="FAQs" />
			<Breadcrumb
				title="FAQs"
				parent={null}
				backgroundImage="./assets/images/bg/bg-image-1.jpg"
			/>
			<div className="rn-about-area ptb--120 bg_color--5">
				<div className="container">
					<div className="row row--35 align-items-center">
						<div className="col-lg-12">
							<div className="about-inner inner">
								<div className="section-title">
									<h2 className="title">
										Häufig gestellte Fragen zu Photovoltaikanlagen
									</h2>
								</div>
								<div className="accordion-wrapper mt--30">
									<FAQsAccordion data={faqPhotovoltaik} />
								</div>
							</div>
						</div>
						<div className="divider" style={{ padding: "32px" }} />
						<div className="col-lg-12">
							<div className="about-inner inner">
								<div className="section-title">
									<h2 className="title">Häufig gestellte Fragen zu Luftwärmepumpen</h2>
								</div>
								<div className="accordion-wrapper mt--30">
									<FAQsAccordion data={faqLuftwaermepumpe} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default FAQsPage;
