import { create } from "zustand";

const localStorageKey = "theme";

const initialTheme = localStorage.getItem(localStorageKey) || "lightmode";

export const useThemeStore = create((set) => ({
	theme: initialTheme,
	toggleTheme: () => {
		set((state) => {
			const newTheme = state.theme === "lightmode" ? "darkmode" : "lightmode";

			localStorage.setItem(localStorageKey, newTheme);

			return { theme: newTheme };
		});
	},
	setDarkMode: () => {
		set({ theme: "darkmode" });
		localStorage.setItem(localStorageKey, "darkmode");
	},
	setLightMode: () => {
		set({ theme: "lightmode" });
		localStorage.setItem(localStorageKey, "lightmode");
	},
}));
