export const faqLuftwaermepumpe = [
	{
		question: "Was ist eine Luftwärmepumpe?",
		answer:
			" Eine Luftwärmepumpe ist eine Heiz- und Kühltechnologie, die Luft als Energiequelle nutzt, um Wärme in Gebäuden zu erzeugen oder abzuführen. Sie arbeitet nach dem Prinzip der Wärmepumpentechnik.",
	},

	{
		question: "Wie funktioniert eine Luftwärmepumpe?",
		answer:
			" Eine Luftwärmepumpe entzieht der Außenluft Wärme, erhöht diese Temperatur mit einem Kältemittel und gibt die Wärme dann in das Gebäude ab. Im Sommer kann der Prozess umgekehrt werden, um das Gebäude zu kühlen.",
	},

	{
		question: "Welche Vorteile bietet eine Luftwärmepumpe?",
		answer:
			" Luftwärmepumpen sind energieeffizient, umweltfreundlich, benötigen keine fossilen Brennstoffe und können sowohl heizen als auch kühlen. Sie können zudem unabhängig von geothermischen Bedingungen installiert werden.",
	},

	{
		question: "Welche Arten von Luftwärmepumpen gibt es?",
		answer:
			" Es gibt Luft-Luft-Wärmepumpen, die die Luft im Gebäude direkt heizen oder kühlen, und Luft-Wasser-Wärmepumpen, die Wärme an das Heizungssystem und das Warmwasser abgeben.",
	},

	{
		question: "Wie effizient sind Luftwärmepumpen?",
		answer:
			" Die Effizienz von Luftwärmepumpen wird durch den COP (Coefficient of Performance) gemessen. In der Regel haben sie einen COP von 2 bis 4, was bedeutet, dass sie 2 bis 4 Einheiten Wärmeenergie für jede verbrauchte Einheit elektrischer Energie erzeugen.",
	},

	{
		question: "Benötigen Luftwärmepumpen spezielle Installation oder Wartung?",
		answer:
			" Ja, Luftwärmepumpen erfordern eine fachmännische Installation und regelmäßige Wartung, um eine optimale Leistung zu gewährleisten. Die Luftfilter müssen gereinigt oder ausgetauscht werden, und das Kältemittel sollte überprüft werden.",
	},

	{
		question: "Welche Faktoren beeinflussen die Effizienz einer Luftwärmepumpe?",
		answer:
			" Die Effizienz einer Luftwärmepumpe wird von Faktoren wie der Außentemperatur, der Isolierung des Gebäudes, der Größe der Anlage und der Qualität der Installation beeinflusst.",
	},

	{
		question: "Kann eine Luftwärmepumpe bei extrem kaltem Wetter effektiv heizen?",
		answer:
			" Luftwärmepumpen können auch bei niedrigen Temperaturen heizen, aber ihre Effizienz kann beeinträchtigt werden. In solchen Fällen sind leistungsstärkere Modelle oder Zusatzheizungen empfehlenswert.",
	},

	{
		question: "Gibt es Umweltauswirkungen bei Luftwärmepumpen?",
		answer:
			" Luftwärmepumpen sind umweltfreundlich, da sie keine direkten Emissionen erzeugen. Die Umweltauswirkungen sind hauptsächlich von der Stromquelle abhängig.",
	},

	{
		question: " Wie lange ist die Lebensdauer einer Luftwärmepumpe?",
		answer:
			" Die Lebensdauer einer Luftwärmepumpe beträgt in der Regel 15-20 Jahre. Die regelmäßige Wartung und Pflege können die Lebensdauer verlängern.",
	},

	{
		question: " Können Luftwärmepumpen in bestehenden Gebäuden installiert werden?",
		answer:
			" Ja, Luftwärmepumpen können in bestehenden Gebäuden nachgerüstet werden, erfordern jedoch eventuell Anpassungen am Heizungssystem und der elektrischen Installation.",
	},

	{
		question: " Benötigen Luftwärmepumpen viel Platz im Freien?",
		answer:
			" Luftwärmepumpen benötigen Platz im Freien für den Aufstellort der Einheit. Die genauen Anforderungen variieren je nach Modell, sind aber in der Regel moderat.",
	},
];
