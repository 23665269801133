import React, { useEffect } from "react";
import { useThemeStore } from "../../stores";
import { Navigation } from "../navigation/Navigation";

export const Header = () => {
	const { theme } = useThemeStore();
	const darkLogo = "./assets/images/logo/logo-dark.svg";
	const lightLogo = "./assets/images/logo/logo-light.svg";

	const logo = theme === "lightmode" ? darkLogo : lightLogo;

	useEffect(() => {
		const elements = document.querySelectorAll(".has-droupdown > a");
		elements.forEach((element) => {
			element.onclick = function () {
				this.parentElement.querySelector(".submenu").classList.toggle("active");
				this.classList.toggle("open");
			};
		});
	}, [theme]);

	return (
		<header className={`header-area header-style-two header--fixed  sticky`}>
			<div className="header-wrapper">
				<div className="header-left d-flex align-items-center">
					<div className="logo">
						<a href="/">
							<img src={logo} alt="Logo" />
						</a>
					</div>
				</div>
				<Navigation />
			</div>
		</header>
	);
};

export default Header;
