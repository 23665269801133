import React from "react";

export const About = () => {
	return (
		<div className="about-area about-position-top pb--120 bg_color--1">
			<div className="about-wrapper pt--60">
				<div className="container">
					<div className="row row--35 align-items-center">
						<div className="col-lg-5 col-md-12">
							<div className="thumbnail">
								<img
									className="w-100"
									src="./assets/images/service/start-01.jpg"
									alt="Startseite Images"
								/>
							</div>
						</div>

						<div className="col-lg-7 col-md-12">
							<div className="about-inner inner">
								<div className="section-title">
									<h2 className="title">Über Uns</h2>
									<p className="description">
										Bei ECO² Building glauben wir daran, Kundenzufriedenheit an erste
										Stelle zu setzen. Unsere Leidenschaft gilt der Bereitstellung von
										erstklassigen Baudienstleistungen im Bereich Hoch- und Tiefbau. Mit
										jahrelanger Erfahrung und einem engagierten Team setzen wir uns für
										Ihre Bauvorhaben ein. Unsere Werte – Qualität, Zuverlässigkeit und
										Präzision – spiegeln sich in all unseren Projekten wider. Wir freuen
										uns darauf, mit Ihnen zusammenzuarbeiten und Ihr Vertrauen zu
										gewinnen.
									</p>
								</div>
								<div className="row mt--30 mt_sm--10">
									<div className="col-lg-6 col-md-12 col-sm-12 col-12">
										<div className="about-us-list">
											<h3 className="title"> Vision</h3>
											<p>Unsere Vision: Erstklassige Bauqualität im Hoch- und Tiefbau.</p>
										</div>
									</div>
									<div className="col-lg-6 col-md-12 col-sm-12 col-12">
										<div className="about-us-list">
											<h3 className="title"> Philosophie</h3>
											<p>Unsere Philosophie: Innovation, Präzision, Transparenz.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
