import React, { useState, useEffect } from "react";

import { PageHelmet, Breadcrumb } from "../../component";
import { JobCard, JobModal } from "./partials";
import { jobs } from "../../utils";

export const JobsPage = () => {
	const [jobToDisplay, setJobToDisplay] = useState({
		id: null,
		title: "Titel",
		company: "Eco 2 Building",
		description: "",
		capacity: "Vollzeit",
		location: "Frankfurt am Main",
		releaseDate: new Date(),
	});

	const showJobModal = (id) => {
		const job = jobs.find((job) => job.id === id);
		if (job) {
			setJobToDisplay(job);
		}
	};

	const hideJobModal = () => {
		setJobToDisplay({
			id: null,
			title: "Titel",
			company: "Eco 2 Building",
			description: "",
			capacity: "Vollzeit",
			location: "Frankfurt am Main",
			releaseDate: new Date(),
		});
	};

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === "Escape") {
				hideJobModal();
			}
		};

		document.addEventListener("keydown", handleKeyDown);

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	return (
		<>
			<PageHelmet pageTitle="Eco² Building - Karriere" />

			<Breadcrumb
				title="Jobs"
				parent={null}
				backgroundImage="./assets/images/service/jobs.jpg"
				showTree={false}
				customCSS={{
					minHeight: "70vh",
					display: "flex",
					alignItems: "center",
				}}
			/>
			<div className="rn-about-area ptb--120 bg_color--1">
				<div className="rn-about-wrapper">
					<div className="container">
						<div className="section-title">
							<h1>WIR SUCHEN DICH!</h1>
							<p
								style={{
									fontSize: "2.5rem",
								}}
							>
								Warum Wir?
							</p>
							<p className="description ptb--80">
								Eco2Building, ein innovatives Unternehmen im Bereich Hoch- und Tiefbau,
								verbindet traditionelle Werte mit modernsten Bautechnologien. Unsere tief
								verwurzelte Erfahrung, die über Generationen hinweg in der Baubranche
								gewachsen ist, bildet das Fundament unserer Arbeit. Als
								verantwortungsbewusstes Familienunternehmen legen wir großen Wert auf
								Nachhaltigkeit und enge Zusammenarbeit mit unseren Kunden und Partnern.
								Jedes unserer Projekte spiegelt unsere Verpflichtung wider, ökologische
								Verantwortung und höchste Bauqualität miteinander zu vereinen, um
								zukunftsfähige und umweltfreundliche Bauwerke zu schaffen.
							</p>
						</div>

						{jobs.map((job) => (
							<JobCard key={job.id} {...job} handleClick={() => showJobModal(job.id)} />
						))}
					</div>
				</div>
			</div>
			{jobToDisplay.id && <JobModal job={jobToDisplay} onClose={hideJobModal} />}
		</>
	);
};
