import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import { NavList } from "../../utils";
import { useThemeStore } from "../../stores";

export const Navigation = () => {
	const { theme } = useThemeStore();
	const { pathname } = useLocation();
	const iconColor = theme === "lightmode" ? "#000" : "#fff";

	const menuTrigger = () => {
		document.querySelector(".header-wrapper").classList.toggle("menu-open");
	};

	const closeMenuTrigger = () => {
		document.querySelector(".header-wrapper").classList.remove("menu-open");
	};

	return (
		<div className="header-right">
			<nav className="mainmenunav d-lg-block ml--50">
				<ul className="mainmenu">
					{NavList.map((item, index) => (
						<li
							key={index}
							className={pathname === item.path ? "is-current" : ""}
							onClick={() => closeMenuTrigger()}
						>
							<Link to={item.path}>{item.linkText}</Link>
						</li>
					))}
				</ul>
			</nav>

			<div className="humberger-menu d-block d-lg-none pl--20">
				<span onClick={menuTrigger} className="menutrigger text-white">
					<FiMenu color={iconColor} />
				</span>
			</div>

			<div className="close-menu d-block d-lg-none">
				<span onClick={closeMenuTrigger} className="closeTrigger">
					<FiX />
				</span>
			</div>
		</div>
	);
};
