import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";

export const BackToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<div className="backto-top">
			<ScrollToTop showUnder={160}>
				<FiChevronUp />
			</ScrollToTop>
		</div>
	);
};
