import React from "react";
import { Link } from "react-router-dom";

export const Breadcrumb = (props) => {
	const {
		title,
		description,
		parent = "",
		backgroundImage = "./assets/images/bg/bg-image-1.jpg",
		showTree = true,
		hasMobileClass = false,
		customCSS = {},
	} = props;

	return (
		<div
			className="breadcrumb-area rn-bg-color ptb--120 bg_image"
			data-black-overlay="6"
			style={{
				backgroundImage: `url(${backgroundImage})`,
				...customCSS,
			}}
		>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="breadcrumb-inner pt--100">
							<h2 className={`title ${hasMobileClass ? "title-mobile" : null}`}>
								{title}
							</h2>
							{description && <p>{description}</p>}
							{showTree && (
								<ul className="page-list">
									<li className="breadcrumb-item">
										<Link to="/">Home</Link>
									</li>
									{parent ? <li className="breadcrumb-item">{parent}</li> : ""}
									<li className={`breadcrumb-item active`}>{title}</li>
								</ul>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Breadcrumb;
