import React, { Fragment } from "react";
import { PageHelmet, Breadcrumb } from "../../component";
import { BusinessInformations } from "../../utils";

export const DatenschutzPage = () => {
	return (
		<Fragment>
			<PageHelmet pageTitle="Datenschutz" />
			<Breadcrumb title="Datenschutz" showTree={false} />
			<div className="rn-about-area ptb--120 bg_color--1">
				<div className="rn-about-wrapper">
					<div className="container">
						<div className="row  align-items-center">
							<div className="col-lg-12">
								<h3>1. Datenschutz auf einen Blick</h3>

								<h4>
									<strong>Allgemeine Hinweise</strong>
								</h4>

								<p>
									Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
									Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
									Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
									identifiziert werden können. Ausführliche Informationen zum Thema
									Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
									Datenschutzerklärung.
								</p>

								<h4>
									<strong>Datenerfassung auf dieser Website</strong>
								</h4>

								<p>
									<strong>
										Wer ist verantwortlich für die Datenerfassung auf dieser Website?
									</strong>
								</p>

								<p>
									Die Datenverarbeitung auf dieser Website erfolgt durch den
									Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
									Website entnehmen.
								</p>

								<p>
									<strong>Wie erfassen wir Ihre Daten?</strong>
								</p>

								<p>
									Ihre Daten werden zum Einen dadurch erhoben, dass Sie uns diese
									mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in
									ein Kontaktformular eingeben.
								</p>

								<p>
									Andere Daten werden automatisch beim Besuch der Website durch unsere
									IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B.
									Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
									Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
									betreten.
								</p>

								<p>
									<strong>Wofür nutzen wir Ihre Daten?</strong>
								</p>

								<p>
									Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
									Website zu gewährleisten. Andere Daten können zur Analyse Ihres
									Nutzerverhaltens verwendet werden.
								</p>

								<p>
									<strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
								</p>

								<p>
									Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
									Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
									erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
									dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
									Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen
									Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei
									der zuständigen Aufsichtsbehörde zu.
								</p>

								<p>
									Außerdem haben Sie das Recht, unter bestimmten Umständen die
									Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
									verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung unter
									„Recht auf Einschränkung der Verarbeitung“.
								</p>

								<h3>2. Hosting</h3>

								<h4>
									<strong>Externes Hosting</strong>
								</h4>

								<p>
									Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
									Personenbezogenen Daten, die auf dieser Website erfasst werden, werden
									auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um
									IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
									Vertragsdaten, Kontaktdaten, Namen, Webseitenzugriffe und sonstige
									Daten, die über eine Website generiert werden, handeln.
								</p>

								<p>
									Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
									gegenüber unseren potentiellen und bestehenden Kunden (Art. 6 Abs. 1
									lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
									Bereitstellung unseres Online-Angebots durch einen professionellen
									Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
								</p>

								<p>
									Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
									Erfüllung seiner Leistungspflichten erforderlich ist und unsere
									Weisungen in Bezug auf diese Daten befolgen.
								</p>

								<h3>3. Allgemeine Hinweise und Pflichtinformationen</h3>

								<h4>
									<strong>Datenschutz</strong>
								</h4>

								<p>
									Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
									sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
									entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
									Datenschutzerklärung.
								</p>

								<p>
									Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
									Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
									persönlich identifiziert werden können. Die vorliegende
									Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
									sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
								</p>

								<p>
									Wir weisen darauf hin, dass die Datenübertragung im Internet (z.&nbsp;B.
									bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
									lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
									möglich.
								</p>

								<h3>Hinweis zur verantwortlichen Stelle</h3>

								<p>
									Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
									ist:
								</p>

								<strong>{BusinessInformations.Unternehmen}</strong>

								<p>Adresse</p>

								<p>
									Kennedyallee 93
									{BusinessInformations.Strasse}
									<br />
									{BusinessInformations.PLZ} {BusinessInformations.Ort}
								</p>

								<p>
									Handelsregister: {BusinessInformations.Handelsregister}
									<br />
									Registergericht: {BusinessInformations.Registergericht}
								</p>

								<p>Vertreten durch: {BusinessInformations.VertretungDurch}</p>

								<p>Kontakt</p>

								<p>
									{BusinessInformations.Email}
									<br />
									Tel.: {BusinessInformations.Telefon}
								</p>

								<p>
									Verantwortliche Stelle ist die natürliche oder juristische Person, die
									allein oder gemeinsam mit anderen über die Zwecke und Mittel der
									Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
									E-Mail-Adressen o. Ä.) entscheidet.
								</p>

								<h4>
									<strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong>
								</h4>

								<p>
									Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
									Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
									jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
									uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
									bleibt vom Widerruf unberührt.
								</p>

								<h4>
									<strong>
										Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
										gegen Direktwerbung (Art. 21 DSGVO)
									</strong>
								</h4>

								<p>
									<strong>
										WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
										F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH
										AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
										PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
										AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
										RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
										DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
										IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
										SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
										VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
										ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG
										ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS.
										1 DSGVO).
									</strong>
								</p>

								<p>
									<strong>
										WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
										BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
										VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
										DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
										SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
										WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
										MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
										ABS. 2 DSGVO).
									</strong>
								</p>

								<h4>
									<strong>Beschwerderecht bei der zuständigen Aufsichtsbehörde</strong>
								</h4>

								<p>
									Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
									Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
									Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
									des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
									unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
									Rechtsbehelfe.
								</p>

								<h4>
									<strong>Recht auf Datenübertragbarkeit</strong>
								</h4>

								<p>
									Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
									oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
									an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
									zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
									Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
									machbar ist.
								</p>

								<h4>
									<strong>Auskunft, Löschung und Berichtigung</strong>
								</h4>

								<p>
									Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
									das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
									personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
									Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
									dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
									Daten können Sie sich jederzeit unter der im Impressum angegebenen
									Adresse an uns wenden.
								</p>

								<h4>
									<strong>Recht auf Einschränkung der Verarbeitung</strong>
								</h4>

								<p>
									Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
									personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
									unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf
									Einschränkung der Verarbeitung besteht in folgenden Fällen:
								</p>

								<ul>
									<li>
										Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
										Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
										überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
										Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
										verlangen.
									</li>

									<li>
										Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
										geschah/geschieht, können Sie statt der Löschung die Einschränkung der
										Datenverarbeitung verlangen.
									</li>

									<li>
										Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
										jedoch zur Ausübung, Verteidigung oder Geltendmachung von
										Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
										die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
										verlangen.
									</li>

									<li>
										Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
										muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
										werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
										haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
										personenbezogenen Daten zu verlangen.
									</li>
								</ul>

								<p>
									Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
									haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
									Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
									von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
									natürlichen oder juristischen Person oder aus Gründen eines wichtigen
									öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
									verarbeitet werden.
								</p>

								<h3>4. Datenerfassung auf dieser Website</h3>

								<h4>
									<strong>Server-Log-Dateien</strong>
								</h4>

								<p>
									Der Provider der Seiten erhebt und speichert automatisch Informationen
									in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
									übermittelt. Dies sind:
								</p>

								<ul>
									<li>Browsertyp und Browserversion</li>

									<li>verwendetes Betriebssystem</li>

									<li>Referrer URL</li>

									<li>Hostname des zugreifenden Rechners</li>

									<li>Uhrzeit der Serveranfrage</li>

									<li>IP-Adresse</li>
								</ul>

								<p>
									Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
									vorgenommen.
								</p>

								<p>
									Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
									f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
									technisch fehlerfreien Darstellung und der Optimierung seiner Website –
									hierzu müssen die Server-Log-Files erfasst werden.
								</p>

								<h4>
									<strong>Kontaktformular</strong>
								</h4>

								<p>
									Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
									Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
									Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
									Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
									Ihre Einwilligung weiter.
								</p>

								<p>
									Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
									lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
									zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
									erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
									unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
									gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
									Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
								</p>

								<p>
									Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns,
									bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
									widerrufen oder der Zweck für die Datenspeicherung entfällt (z.&nbsp;B.
									nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
									Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
								</p>

								<h4>
									<strong>Anfrage per E-Mail, Telefon oder Telefax</strong>
								</h4>

								<p>
									Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
									Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
									(Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
									gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
									Einwilligung weiter.
								</p>

								<p>
									Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
									lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
									zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
									erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
									Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder auf unseren
									berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein
									berechtigtes Interesse an der effektiven Bearbeitung der an uns
									gerichteten Anfragen haben.
								</p>

								<p>
									Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
									bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
									Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
									(z.&nbsp;B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
									gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen
									– bleiben unberührt.
								</p>

								<h3>5. Eigene Dienste</h3>

								<h4>
									<strong>Umgang mit Bewerberdaten</strong>
								</h4>

								<p>
									Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z.&nbsp;B.
									per E-Mail, postalisch oder via Online-Bewerberformular). Im Folgenden
									informieren wir Sie über Umfang, Zweck und Verwendung Ihrer im Rahmen
									des Bewerbungsprozesses erhobenen personenbezogenen Daten. Wir
									versichern, dass die Erhebung, Verarbeitung und Nutzung Ihrer Daten in
									Übereinstimmung mit geltendem Datenschutzrecht und allen weiteren
									gesetzlichen Bestimmungen erfolgt und Ihre Daten streng vertraulich
									behandelt werden.
								</p>

								<p>
									<strong>Umfang und Zweck der Datenerhebung</strong>
								</p>

								<p>
									Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre damit
									verbundenen personenbezogenen Daten (z.&nbsp;B. Kontakt- und
									Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von
									Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die
									Begründung eines Beschäftigungsverhältnisses erforderlich ist.
									Rechtsgrundlage hierfür ist § 26 BDSG-neu nach deutschem Recht
									(Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b
									DSGVO (allgemeine Vertragsanbahnung) und – sofern Sie eine Einwilligung
									erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die Einwilligung ist
									jederzeit widerrufbar. Ihre personenbezogenen Daten werden innerhalb
									unseres Unternehmens ausschließlich an Personen weitergegeben, die an
									der Bearbeitung Ihrer Bewerbung beteiligt sind.
								</p>

								<p>
									Sofern die Bewerbung erfolgreich ist, werden die von Ihnen eingereichten
									Daten auf Grundlage von § 26 BDSG-neu und Art. 6 Abs. 1 lit. b DSGVO zum
									Zwecke der Durchführung des Beschäftigungsverhältnisses in unseren
									Datenverarbeitungssystemen gespeichert.
								</p>

								<p>
									<strong>Aufbewahrungsdauer der Daten</strong>
								</p>

								<p>
									Sofern wir Ihnen kein Stellenangebot machen können, Sie ein
									Stellenangebot ablehnen oder Ihre Bewerbung zurückziehen, behalten wir
									uns das Recht vor, die von Ihnen übermittelten Daten auf Grundlage
									unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) bis zu 6
									Monate ab der Beendigung des Bewerbungsverfahrens (Ablehnung oder
									Zurückziehung der Bewerbung) bei uns aufzubewahren. Anschließend werden
									die Daten gelöscht und die physischen Bewerbungsunterlagen vernichtet.
									Die Aufbewahrung dient insbesondere Nachweiszwecken im Falle eines
									Rechtsstreits. Sofern ersichtlich ist, dass die Daten nach Ablauf der
									6-Monatsfrist erforderlich sein werden (z.B. aufgrund eines drohenden
									oder anhängigen Rechtsstreits), findet eine Löschung erst statt, wenn
									der Zweck für die weitergehende Aufbewahrung entfällt.
								</p>

								<p>
									Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine
									entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt haben
									oder wenn gesetzliche Aufbewahrungspflichten der Löschung
									entgegenstehen.
								</p>

								<p>Stand Oktober 2023</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default DatenschutzPage;
