import React, { Fragment } from "react";
import { PageHelmet, Breadcrumb } from "../../component";
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";

export const DetailsPage = () => {
	const SocialShare = [
		{ Social: <FaFacebookF />, link: "https://www.facebook.com/" },
		{ Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
		{ Social: <FaInstagram />, link: "https://www.instagram.com/" },
		{ Social: <FaTwitter />, link: "https://twitter.com/" },
	];

	return (
		<Fragment>
			<PageHelmet pageTitle="Details" />
			<Breadcrumb title="Details" />
			{/* TODO: add bg_image--4 as backgreound image in header */}

			<div className="rn-portfolio-details ptb--120 bg_color--1">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="portfolio-details">
								<div className="inner">
									<h2>Trydo</h2>
									<p className="subtitle">
										There are many variations of passages of Lorem Ipsum available, but
										the majority have suffered alteration in some form.
									</p>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
										eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
										suspendisse ultrices gravida. Risus commod viverra maecenas accumsan
										lacus vel facilisis. ut labore et dolore magna aliqua.{" "}
									</p>

									<div className="portfolio-view-list d-flex flex-wrap">
										<div className="port-view">
											<span>Branch</span>
											<h4>Ability</h4>
										</div>

										<div className="port-view">
											<span>Project Types</span>
											<h4>Website</h4>
										</div>

										<div className="port-view">
											<span>Program</span>
											<h4>View Project</h4>
										</div>
									</div>

									<div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
										<ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
											{SocialShare.map((val, i) => (
												<li key={i}>
													<a href={`${val.link}`}>{val.Social}</a>
												</li>
											))}
										</ul>
									</div>
								</div>
								<div className="portfolio-thumb-inner">
									<div className="thumb mb--30">
										<img
											src="./assets/images/portfolio/portfolio-big-02.jpg"
											alt="Portfolio Images"
										/>
									</div>

									<div className="thumb">
										<img
											src="./assets/images/portfolio/portfolio-big-01.jpg"
											alt="Portfolio Images"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};
