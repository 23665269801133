import React from "react";

export const Quote = () => {
	return (
		<div className="rn-testimonial-area bg_color--5 ptb--120">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="react-tabs" data-rttabs="true">
							<div
								className="react-tabs__tab-panel react-tabs__tab-panel--selected"
								role="tabpanel"
							>
								<div className="rn-testimonial-content text-center">
									<div
										className="inner"
										style={{
											padding: 0,
										}}
									>
										<p>
											Ein gutes Fundament ist der Grundstein für jedes Bauwerk, egal wie
											hoch oder tief
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
