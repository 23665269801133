import React from "react";

const ServiceList = [
	{
		icon: "01",
		title: "Kundenzufriedenheit ",
		description:
			"Erfahren Sie, wie wir Kundenzufriedenheit zur Priorität machen, um Ihnen das beste Serviceerlebnis zu bieten.",
	},
	{
		icon: "02",
		title: "Hoch- und Tiefbau",
		description:
			"Entdecken Sie, wie unsere Expertise im Hoch- und Tiefbau Ihnen hilft, Bauprojekte effizient und zuverlässig umzusetzen, stets mit höchsten Qualitätsstandards.",
	},
	{
		icon: "03",
		title: "Baudienstleistungen",
		description:
			"Erfahren Sie mehr über unsere vielfältigen Baudienstleistungen, die auf höchste Qualität und Zuverlässigkeit setzen.",
	},
];

export const Slider = () => {
	return (
		<div className="slider-activation">
			<div
				style={{
					backgroundImage: `url(./assets/images/bg/general-slide.jpg)`,
				}}
				className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image"
			>
				<div className="container position-relative">
					<div className="row">
						<div className="col-lg-12">
							<div className="inner">
								<h1 className="title theme-gradient">
									Die Zukunft <br /> besser gestalten.
								</h1>
							</div>
						</div>
					</div>

					<div className="service-wrapper service-white">
						<div className="slider-wrapper">
							<div className="row">
								{ServiceList.map((val, i) => {
									const { icon, title, description } = val;
									return (
										<div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
											<div className="service service__style--1">
												<div className="icon">
													<img
														src={`./assets/images/icons/icon-${icon}.png`}
														alt={title}
													/>
												</div>
												<div className="content">
													<h4 className="title">{title}</h4>
													<p>{description}</p>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
