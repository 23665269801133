import React, { useState, useRef } from "react";

export const KontaktForm = () => {
	const [result, setResult] = useState({
		success: false,
		error: false,
	});

	const formRef = useRef(null);

	const sendEmail = (e) => {
		e.preventDefault();

		const formData = new FormData(e.target);

		fetch("backend/sendMail.php", {
			method: "POST",
			body: JSON.stringify(Object.fromEntries(formData)),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					setResult({ success: true, error: false });
					resetForm();
				} else {
					setResult({ success: false, error: true });
				}
			})
			.catch((error) => {
				console.error("Error sending email:", error);
				setResult({ success: false, error: true });
			});
	};

	const resetForm = () => {
		if (formRef.current) {
			formRef.current.reset();
		}
	};

	setTimeout(() => {
		setResult({ success: false, error: false });
	}, 5000);

	return (
		<form ref={formRef} action="" onSubmit={sendEmail}>
			<div className="rn-form-group">
				<input type="text" name="fullname" placeholder="Name" required />
			</div>

			<div className="rn-form-group">
				<input type="email" name="email" placeholder="Email" required />
			</div>

			<div className="rn-form-group">
				<input type="text" name="phone" placeholder="Telefon" required />
			</div>

			<div className="rn-form-group">
				<input type="text" name="subject" placeholder="Betreff" required />
			</div>

			<div className="rn-form-group">
				<textarea name="message" placeholder="Ihre Nachricht" required></textarea>
			</div>

			<div className="rn-form-group">
				<button
					className="rn-button-style--2 btn-solid"
					type="submit"
					value="submit"
					name="submit"
					id="mc-embedded-subscribe"
				>
					Senden
				</button>
			</div>

			<div className="rn-form-group">
				{result.success && (
					<p className="success-message">Ihre Nachricht wurde erfolgreich gesendet.</p>
				)}
				{result.error && (
					<p className="error-message">
						Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
					</p>
				)}
			</div>
		</form>
	);
};
