import { useThemeStore } from "../../../stores";

const theme = useThemeStore.getState().theme;

export const WaermepumpeBrennstoff = [
	{
		key: "brennstoff",
		title: "Erdgas",
		image: `./assets/images/kostenrechner/erdgas-${theme}.png`,
	},
	{
		key: "brennstoff",
		title: "Flüssiggas",
		image: `./assets/images/kostenrechner/fluessiggas-${theme}.png`,
	},
	{
		key: "brennstoff",
		title: "Öl",
		image: `./assets/images/kostenrechner/heizoel-${theme}.png`,
	},
	{
		key: "brennstoff",
		title: "Anderes",
		image: "./assets/images/kostenrechner/weiteres.png",
	},
];

export const WaermepumpeHeizEnergie = [
	{
		key: "energieverbrauch",
		title: "5.000 - 20.000 kWh",
		image: "./assets/images/kostenrechner/kwh-icon.png",
	},
	{
		key: "energieverbrauch",
		title: "21.000 - 25.000 kWh",
		image: "./assets/images/kostenrechner/kwh-icon.png",
	},
	{
		key: "energieverbrauch",
		title: "26.000 - 30.000 kWh",
		image: "./assets/images/kostenrechner/kwh-icon.png",
	},
	{
		key: "energieverbrauch",
		title: "31.000 - 35.000 kWh",
		image: "./assets/images/kostenrechner/kwh-icon.png",
	},
	{
		key: "energieverbrauch",
		title: "41.000 - 45.000 kWh",
		image: "./assets/images/kostenrechner/kwh-icon.png",
	},
	{
		key: "energieverbrauch",
		title: "+45.000",
		image: "./assets/images/kostenrechner/kwh-icon.png",
	},
	{
		key: "energieverbrauch",
		title: "Ich weiß es nicht",
		image: "./assets/images/kostenrechner/kwh-icon.png",
	},
];

export const WaermepumpeHeizungInBetrieb = [
	{
		key: "heizunginbetrieb",
		title: "30 Jahre und älter",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
	{
		key: "heizunginbetrieb",
		title: "20-29 Jahre",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
	{
		key: "heizunginbetrieb",
		title: "10-19 Jahre",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
	{
		key: "heizunginbetrieb",
		title: "0-9 Jahre",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
	{
		key: "heizunginbetrieb",
		title: "Ich weiß es nicht",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
];

export const WaermepumpeGebaeudeArt = [
	{
		key: "gebaeudeart",
		title: "Einzelhaus",
		image: "./assets/images/kostenrechner/einzelhaus.png",
	},
	{
		key: "gebaeudeart",
		title: "Doppelhaushälfte",
		image: `./assets/images/kostenrechner/doppelhaus-${theme}.png`,
	},
	{
		key: "gebaeudeart",
		title: "Reihenhaus",
		image: "./assets/images/kostenrechner/reihenhaus.png",
	},
	{
		key: "gebaeudeart",
		title: "Reihenmittelhaus",
		image: `./assets/images/kostenrechner/reihenhausmitte-${theme}.png`,
	},
	{
		key: "gebaeudeart",
		title: "Mehrfamilienhaus",
		image: "./assets/images/kostenrechner/mehrfamilienhaus.png",
	},
	{
		key: "gebaeudeart",
		title: "Wohnung",
		image: "./assets/images/kostenrechner/wohnung.png",
	},
];

export const WaermepumpeGebaeudeAlter = [
	{
		key: "gebaeudealter",
		title: "Vor 1960",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
	{
		key: "gebaeudealter",
		title: "1961 - 1977",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
	{
		key: "gebaeudealter",
		title: "1978 - 1994",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
	{
		key: "gebaeudealter",
		title: "1995 - 2001",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
	{
		key: "gebaeudealter",
		title: "ab 2002",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
	{
		key: "gebaeudealter",
		title: "im Bau",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
];

export const WaermepumpeGebaeudeWohnflaeche = [
	{
		key: "wohnflaeche",
		title: "bis 100 m²",
		image: "./assets/images/kostenrechner/flaeche.png",
	},
	{
		key: "wohnflaeche",
		title: "101 - 150 m²",
		image: "./assets/images/kostenrechner/flaeche.png",
	},
	{
		key: "wohnflaeche",
		title: "151 - 200 m²",
		image: "./assets/images/kostenrechner/flaeche.png",
	},
	{
		key: "wohnflaeche",
		title: "201 - 300 m²",
		image: "./assets/images/kostenrechner/flaeche.png",
	},
	{
		key: "wohnflaeche",
		title: "über 300 m²",
		image: "./assets/images/kostenrechner/flaeche.png",
	},
];

export const WaermepumpeTrinkwasserHeizung = [
	{
		key: "trinkwasserheizung",
		title: "Ja",
		image: "./assets/images/kostenrechner/trinkwasserheizung.png",
	},
	{
		key: "trinkwasserheizung",
		title: "Nein",
		image: "./assets/images/kostenrechner/trinkwasserheizung.png",
	},
	{
		key: "trinkwasserheizung",
		title: "Ich weiß es nicht",
		image: "./assets/images/kostenrechner/trinkwasserheizung.png",
	},
];

export const WaermepumpeHeizMethode = [
	{
		key: "heizmethode",
		title: "Heizkörper",
		image: "./assets/images/kostenrechner/heizkoerper.png",
	},
	{
		key: "heizmethode",
		title: "Fußbodenheizung",
		image: "./assets/images/kostenrechner/bodenheizung.png",
	},
	{
		key: "heizmethode",
		title: "Wandheizung",
		image: "./assets/images/kostenrechner/wandheizung.png",
	},
	{
		key: "heizmethode",
		title: "Deckenheizung",
		image: "./assets/images/kostenrechner/deckenheizung.png",
	},
	{
		key: "heizmethode",
		title: "Anderweitig",
		image: "./assets/images/kostenrechner/weiteres.png",
	},
];

export const WaermepumpeInstallationsDatum = [
	{
		key: "installationsdatum",
		title: "Sobald wie möglich",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
	{
		key: "installationsdatum",
		title: "Innerhalb der nächsten 3 Monate",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
	{
		key: "installationsdatum",
		title: "Innerhalb der nächsten 6 Monate",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
	{
		key: "installationsdatum",
		title: "Innerhalb dieses Jahres",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
	{
		key: "installationsdatum",
		title: "In mehr als einem Jahr",
		image: "./assets/images/kostenrechner/year-icon.png",
	},
];

export const WaermepumpeProblemeMitAktuellerHeizung = [
	{
		key: "problememitheizung",
		title: "Nein",
		image: "./assets/images/kostenrechner/problem.png",
	},
	{
		key: "problememitheizung",
		title: "Ja",
		image: "./assets/images/kostenrechner/problem.png",
	},
	{
		key: "problememitheizung",
		title: "Eingeschränkt",
		image: "./assets/images/kostenrechner/problem.png",
	},
];

export const WaermepumpeStandortHeizungsanlage = [
	{
		key: "standortheizungsanlage",
		title: "Keller",
		image: `./assets/images/kostenrechner/heizung-standort-keller-${theme}.png`,
	},
	{
		key: "standortheizungsanlage",
		title: "Dachboden",
		image: `./assets/images/kostenrechner/heizung-standort-dachboden-${theme}.png`,
	},
	{
		key: "standortheizungsanlage",
		title: "Wohnraum",
		image: `./assets/images/kostenrechner/heizung-standort-wohnung-${theme}.png`,
	},
	{
		key: "standortheizungsanlage",
		title: "Außenbereich",
		image: `./assets/images/kostenrechner/heizung-standort-aussen-${theme}.png`,
	},
	{
		key: "standortheizungsanlage",
		title: "Anderes",
		image: "./assets/images/kostenrechner/weiteres.png",
	},
];
