import React from "react";
import ReactDOM from "react-dom";
import { AppRoutes } from "./routes";

import "./index.scss";

function Root() {
	return <AppRoutes />;
}

ReactDOM.render(<Root />, document.getElementById("root"));
