import React, { useEffect } from "react";

import { Header, Footer, TopBar, ThemeSelector, BackToTop } from "../component";
import { useLocation } from "react-router-dom";
import { useThemeStore } from "../stores";

export const WebsiteTemplate = ({ children }) => {
	const { pathname } = useLocation();

	const { theme } = useThemeStore();

	const activeTheme = theme === "lightmode" ? "active-light" : "active-dark";

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<div className={activeTheme}>
			<TopBar />
			<Header />
			{children}
			<ThemeSelector />
			<BackToTop />
			<Footer />
		</div>
	);
};

export default WebsiteTemplate;
