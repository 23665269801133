import React from "react";

export const Beratung = () => {
	return (
		<div className="rn-finding-us-area rn-finding-us bg_color--1">
			<div className="inner">
				<div className="content-wrapper">
					<div className="content">
						<h4 className="theme-gradient">Beratung nach Maß</h4>
						<p>
							Unsere Experten stehen Ihnen zur Seite, um maßgeschneiderte Lösungen für
							Ihre Bedürfnisse zu finden. Von Anfang bis Ende sind wir Ihr zuverlässiger
							Partner. Kontaktieren Sie uns heute, um Ihr Projekt zu besprechen.
						</p>
						<a className="rn-btn btn-white" href="/kontakt">
							Jetzt Beratung anfordern
						</a>
					</div>
				</div>
				<div className="thumbnail">
					<div className="image">
						<img src="./assets/images/about/about-02.webp" alt="Finding Images" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Beratung;
