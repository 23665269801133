import React, { Fragment } from "react";
import { PageHelmet, Breadcrumb } from "../../component";
import { BusinessInformations } from "../../utils";

export const ImpressumPage = () => {
	return (
		<Fragment>
			<PageHelmet pageTitle="Impressum" />
			<Breadcrumb title="Impressum" />

			<div className="rn-about-area ptb--120 bg_color--1">
				<div className="rn-about-wrapper">
					<div className="container">
						<div className="row  align-items-center">
							<div className="col-lg-12">
								<p>Angaben gemäß § 5 TMG</p>

								<strong>{BusinessInformations.Unternehmen}</strong>

								<p>Adresse</p>

								<p>
									{BusinessInformations.Strasse}
									<br />
									{BusinessInformations.PLZ} {BusinessInformations.Ort}
								</p>

								<p>
									Handelsregister: {BusinessInformations.Handelsregister}
									<br />
									Registergericht: {BusinessInformations.Registergericht}
								</p>

								<p>Vertreten durch: {BusinessInformations.VertretungDurch}</p>

								<p>Kontakt</p>

								<p>
									{BusinessInformations.Email}
									<br />
									Tel.: {BusinessInformations.Telefon}
								</p>

								<p>
									Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
									vor einer Verbraucherschlichtungsstelle teilzunehmen.
								</p>

								<p>
									<strong>Haftung für Inhalte</strong>
									<br />
									Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
									diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
									bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
									übermittelte oder gespeicherte fremde Informationen zu überwachen oder
									nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
									hinweisen.
								</p>

								<p>
									Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
									Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
									Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
									Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
									entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
									entfernen.
								</p>

								<p>
									<strong>Haftung für Links</strong>
									<br />
									Unser Angebot enthält Links zu externen Websites Dritter, auf deren
									Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
									Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
									Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
									verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
									Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
									waren zum Zeitpunkt der Verlinkung nicht erkennbar.
								</p>

								<p>
									Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
									ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
									Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
									entfernen.
								</p>

								<p>
									<strong>Urheberrecht</strong>
									<br />
									Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
									Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
									Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
									Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
									jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
									sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
								</p>

								<p>
									Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
									werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
									Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
									Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
									entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
									wir derartige Inhalte umgehend entfernen.
								</p>

								<p>Frankfurt am Main, Stand 10/2023</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ImpressumPage;
