import React, { Fragment } from "react";
import { PageHelmet, PageSlider, ServiceSlider } from "../../component";

export const HochbauPage = () => {
	const Leistungen = [
		{
			id: 0,
			title: "Hochbau",
			description:
				"Unsere Hochbau-Expertise basiert auf langjähriger Erfahrung und einem starken Engagement für Qualität und Präzision. Wir bieten umfassende Hochbauleistungen, darunter Neubau, Sanierung und Umbau. Unser Ziel ist es, Ihre Visionen wahr werden zu lassen, indem wir innovative Lösungen und nachhaltiges Design in jedes Projekt integrieren. Vertrauen Sie uns, um Ihr Hochbauprojekt in die Höhe zu treiben und exzellente Ergebnisse zu erzielen.",
			image: "./assets/images/service/hochbau-1.png",
			imageSide: "right",
			itemsTitle: "Hochbauleistungen",
			items: [
				"Neubau von Wohn- und Gewerbegebäuden",
				"Sanierung und Renovierung bestehender Strukturen",
				"Umbau und Erweiterungen von Gebäuden",
				"Bauprojektmanagement und Koordination",
				"Nachhaltige Baupraktiken und ökologisches Design",
				"Einhaltung höchster Qualitätsstandards",
				"Nutzung innovativer Bautechnologien",
				"Barrierefreie Gebäude und Inklusionslösungen",
				"Expertise in Bauvorschriften und Genehmigungen",
			],
		},
		{
			id: 1,
			title: "Uweltbewusstes Hochbau",
			description:
				"Beim Bau von Gebäuden stehen Ihnen verschiedene Techniken und Materialien zur Auswahl. Zwei der bekanntesten Methoden sind die Massivbauweise und die Skelettbauweise, die sich in ihren Ansätzen unterscheiden. Massivbauten zeichnen sich dadurch aus, dass tragende und raumabschließende Funktionen nicht getrennt werden. Wände und Decken spielen dabei eine entscheidende statische Rolle. Für den Bau von Gebäuden werden in der Regel Mauerwerk und Stahlbeton verwendet. Es gibt jedoch auch Fälle, in denen Holz in der sogenannten Blockbauweise eingesetzt wird. Stahl wird hauptsächlich in der Skelettbauweise verwendet, da er aufgrund seiner Linienform nicht für die Massivbauweise geeignet ist. Bei dieser Bauweise entsteht keine massive Struktur, sondern ein Gerüst aus Tragstrukturen.",
			image: "./assets/images/service/hochbau-2.png",
			imageSide: "left",
		},
	];

	const slides = [
		{
			textPosition: "text-right",
			bgImage: "./assets/images/bg/hochbau.jpg",
			title: "Hochbau",
			description:
				"Ihre Bauprojekte verdienen die beste Handwerkskunst und höchste Sorgfalt. Unsere Bauleistungen sind darauf ausgerichtet, Ihre Erwartungen zu übertreffen und Ihre Projekte erfolgreich abzuschließen.",
		},
	];

	return (
		<Fragment>
			<PageHelmet pageTitle="Eco² Building - Hochbau" />
			<PageSlider slides={slides} />
			{Leistungen.map((leistung) => {
				return (
					<ServiceSlider
						key={leistung.id}
						title={leistung.title}
						description={leistung.description}
						image={leistung.image}
						imageSide={leistung.imageSide}
						itemsTitle={leistung.itemsTitle}
						items={leistung.items}
					/>
				);
			})}
		</Fragment>
	);
};

export default HochbauPage;
