import React from "react";
import { FiCheck } from "react-icons/fi";

export const ServiceSlider = (props) => {
	const { title, description, imageSide, image, itemsTitle, items } = props;

	// On mobile (default), image is shown first; adjust for large screens based on imageSide
	const imageOrder = imageSide === "left" ? "order-1 order-lg-1" : "order-1 order-lg-2";
	const contentOrder = imageSide === "left" ? "order-2 order-lg-2" : "order-2 order-lg-1";

	const Image = () => {
		return (
			<div className={`col-lg-6 ${imageOrder}`}>
				<div className="thumbnail position-relative">
					<img className="w-100" src={image} alt="About Images" />
				</div>
			</div>
		);
	};

	return (
		<div className="rn-about-area ptb--120 bg_color--1">
			<div className="container">
				<div className="row row--35 align-items-center">
					<div className={`col-lg-6 ${contentOrder}`}>
						<div className="about-inner inner">
							<div className="section-title">
								<h2 className="title">{title}</h2>
								<p className="description">{description}</p>
							</div>
							<div className="mt--30">
								{itemsTitle && <h4>{itemsTitle}</h4>}
								{items && (
									<ul className="list-style--1">
										{items.map((name, index) => {
											return (
												<li key={index}>
													<FiCheck /> {name}
												</li>
											);
										})}
									</ul>
								)}
							</div>
						</div>
					</div>
					<Image />
				</div>
			</div>
		</div>
	);
};
