import React from "react";

export const UeberUns = () => {
	return (
		<div className="rn-about-area ptb--120 bg_color--1">
			<div className="rn-about-wrapper">
				<div className="container">
					<div className="row row--35 align-items-center">
						<div className="col-lg-5">
							<div className="thumbnail">
								<img
									className="w-100"
									src="./assets/images/about/about-01.webp"
									alt="About Images"
								/>
							</div>
						</div>
						<div className="col-lg-7">
							<div className="about-inner inner">
								<div className="section-title">
									<h2 className="title">Wir sind..</h2>
									<p className="description">
										.. ein leidenschaftliches Team von Fachleuten, das sich auf
										Baudienstleistungen spezialisiert hat. Seit unserer Gründung haben wir
										uns dem Ziel verschrieben, die Zukunft besser und nachhaltiger zu
										gestalten. Unser Engagement für höchste Qualität, Kundenzufriedenheit
										und Umweltfreundlichkeit treibt uns täglich an. Mit unserer Expertise
										und unserer Leidenschaft setzen wir uns für innovative Lösungen in der
										Baubranche und erneuerbaren Energien ein. Vertrauen Sie auf unsere
										Erfahrung und unsere verlässliche Arbeit, um Ihre Projekte erfolgreich
										umzusetzen und die Welt gemeinsam zu einem besseren Ort zu machen.
									</p>
								</div>
								<div className="row mt--30">
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<div className="about-us-list">
											<h3 className="title">Wir freuen uns..</h3>
											<p>
												..über jeden, der sich dazu entscheidet mit uns, ECO² Building in
												die Zukunft zu starten. Denn die Zukunft beginnt jetzt – bei Ihnen
												zu Hause!
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UeberUns;
