import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

export const Counter = () => {
	const [didViewCountUp, setDidViewCountUp] = useState(false);
	const [projectsCount, setProjectsCount] = useState(102);
	const [aktuelleProjekte, setAktuelleProjekte] = useState(0);
	const [satisfiedClients, setSatisfiedClients] = useState(74);

	const startDate = new Date("2024-01-02");
	const currentDate = new Date();

	const timeDifference = currentDate - startDate;
	const monthsPassed = timeDifference / (1000 * 60 * 60 * 24 * 30);

	const onVisibilityChange = (isVisible) => {
		if (isVisible) setDidViewCountUp(true);
	};

	useEffect(() => {
		// setaktuelle projekte random between 4 and 6 every 3 months
		const incrementClientsAndProjects = () => {
			setAktuelleProjekte(Math.floor(Math.random() * (6 - 4 + 1)) + 4);
		};

		if (monthsPassed >= 2) {
			const increments = Math.floor(monthsPassed / 3);
			for (let i = 0; i < increments; i++) {
				incrementClientsAndProjects();
			}
		}
	}, [monthsPassed]);

	useEffect(() => {
		// Function to increment satisfied clients and done projects every 3 months
		const incrementClientsAndProjects = () => {
			setSatisfiedClients((prevClients) => prevClients + 1);
			setProjectsCount((prevProjects) => prevProjects + 1);
		};

		if (monthsPassed >= 3) {
			const increments = Math.floor(monthsPassed / 3);
			for (let i = 0; i < increments; i++) {
				incrementClientsAndProjects();
			}
		}
	}, [monthsPassed]);

	return (
		<div className="rn-counterup-area pt--25 pb--110 bg_color--1">
			<div className="container">
				<div className="row">
					<div className="col-lg-12" style={{ marginTop: "32px" }}>
						<div className="text-center ">
							<h3 className="fontWeight500">Ein Paar Fakten über ECO² Building</h3>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12">
						<h5 className="counter">
							<VisibilitySensor
								onChange={onVisibilityChange}
								offset={{ top: 10 }}
								delayedCall
							>
								<CountUp end={didViewCountUp ? projectsCount : 0} />
							</VisibilitySensor>
						</h5>
						<p className="description">Abgeschlossene Projekte</p>
					</div>
					<div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12">
						<h5 className="counter">
							<VisibilitySensor
								onChange={onVisibilityChange}
								offset={{ top: 10 }}
								delayedCall
							>
								<CountUp end={didViewCountUp ? aktuelleProjekte : 0} />
							</VisibilitySensor>
						</h5>
						<p className="description">Aktuelle Projekte</p>
					</div>
					<div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12">
						<h5 className="counter">{satisfiedClients}</h5>
						<p className="description">Zufriedene Kunden</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Counter;
