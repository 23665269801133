import React from "react";
import "./JobModal.style.scss";

export const JobModal = ({ job, onClose }) => {
	const {
		id,
		title,
		company,
		description,
		capacity,
		location,
		releaseDate,
		qualifications = [],
		requirements = [],
	} = job || {};

	// Return null if there's no job to display
	if (!id) return null;

	// Prepare the mailto link
	const mailtoLink = `mailto:info@eco2-building.de?subject=Application for ${title} at ${company}&body=Sehr geehrte Damen und Herren,%0D%0A%0D%0AIch möchte mich auf die Position ${title} bei ${company} bewerben.%0D%0A%0D%0AMit freundlichen Grüßen,%0D%0A[Ihr Name]`;

	// Close modal when clicking outside content
	const handleBackgroundClick = () => {
		onClose();
	};

	// Prevent background click from closing modal
	const handleContentClick = (event) => {
		event.stopPropagation();
	};

	return (
		<div className="jobmodal" onClick={handleBackgroundClick}>
			<div className="jobmodal-content" onClick={handleContentClick}>
				<div className="jobmodal-content-header">
					<div className="jobmodal-content-header-left">
						<div className="title">{title}</div>
						<div className="company">{company}</div>
					</div>
					<div className="jobmodal-content-header-right">
						<button onClick={onClose}>Schließen</button>
					</div>
				</div>
				<div className="jobmodal-content-body">
					<p>{description}</p>
					<p>
						<strong>Kapazität:</strong> {capacity}
					</p>
					<p>
						<strong>Ort:</strong> {location}
					</p>
					<p>
						<strong>Veröffentlichungsdatum:</strong> {releaseDate.toLocaleDateString()}
					</p>

					{/* Qualifications Section */}
					{qualifications.length > 0 && (
						<div className="qualifications">
							<h3>Qualifikationen</h3>
							<ul>
								{qualifications.map((qual, index) => (
									<li key={index}>{qual}</li>
								))}
							</ul>
						</div>
					)}

					{/* Requirements Section */}
					{requirements.length > 0 && (
						<div className="requirements">
							<h3>Anforderungen</h3>
							<ul>
								{requirements.map((req, index) => (
									<li key={index}>{req}</li>
								))}
							</ul>
						</div>
					)}

					{/* Apply Button */}
					<div className="apply-button">
						<a href={mailtoLink} className="apply-link">
							Jetzt Bewerben
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
