import React, { Fragment } from "react";
import { PageHelmet, PageSlider } from "../../component";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import { KontaktForm } from "./partials";
import { BusinessInformations } from "../../utils";
import { useThemeStore } from "../../stores";

const slides = [
	{
		textPosition: "text-right",
		bgImage: "./assets/images/bg/kontakt.jpg",
		title: "Kontakt",
	},
];

export const KontaktPage = () => {
	const { theme } = useThemeStore();

	const kontaktDark = "./assets/images/kontakt/kontakt-light.png";
	const kontaktLight = "./assets/images/kontakt/kontakt-dark.png";

	const kontaktImage = theme === "lightmode" ? kontaktLight : kontaktDark;

	return (
		<Fragment>
			<PageHelmet pageTitle="Eco² Building - Kontakt" />

			<PageSlider slides={slides} />

			<div className="rn-contact-top-area ptb--120 bg_color--5">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-6 col-12">
							<div className="rn-address">
								<div className="icon">
									<FiHeadphones />
								</div>
								<div className="inner">
									<h4
										className="title"
										style={{
											color: "#026916",
										}}
									>
										Telefon
									</h4>
									<p>
										<a href={`tel:${BusinessInformations.Telefon}`}>
											{BusinessInformations.Telefon}
										</a>
									</p>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
							<div className="rn-address">
								<div className="icon">
									<FiMail />
								</div>
								<div className="inner">
									<h4
										className="title"
										style={{
											color: "#026916",
										}}
									>
										Email
									</h4>
									<p>
										<a href={`mailto:${BusinessInformations.Email}`}>
											{BusinessInformations.Email}
										</a>
										<br />
									</p>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
							<div className="rn-address">
								<div className="icon">
									<FiMapPin />
								</div>
								<div className="inner">
									<h4
										className="title"
										style={{
											color: "#026916",
										}}
									>
										Adresse
									</h4>
									<p>{BusinessInformations.Strasse}</p>
									<p>
										{BusinessInformations.PLZ} {BusinessInformations.Ort}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="rn-contact-page ptb--120 bg_color--1">
				<div className="contact-form--1">
					<div className="container">
						<div className="row row--35 align-items-start">
							<div className="col-lg-6 order-2 order-lg-1">
								<div className="section-title text-left mb--50">
									<h2 className="title">Formular</h2>
									<p className="description">
										Bei Fragen oder Anliegen stehen wir Ihnen gerne zur Verfügung.
									</p>
								</div>
								<div className="form-wrapper">
									<KontaktForm />
								</div>
							</div>
							<div className="col-lg-6 order-1 order-lg-2">
								<div className="thumbnail mb_md--30 mb_sm--30">
									<img src={kontaktImage} alt="Kontakt" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default KontaktPage;
