import React, { Fragment } from "react";
import { PageHelmet, Breadcrumb } from "../../component";
import { UeberUns, Quote, Beratung } from "./partials";

export const AboutPage = () => {
	return (
		<Fragment>
			<PageHelmet pageTitle="Eco² Building - Über Uns" />
			<Breadcrumb
				title="Über Uns"
				parent={null}
				backgroundImage="./assets/images/bg/general-slide.jpg"
				showTree={false}
				customCSS={{
					minHeight: "70vh",
					display: "flex",
					alignItems: "center",
				}}
			/>

			<UeberUns />
			<Beratung />
			<Quote />
		</Fragment>
	);
};

export default AboutPage;
