import React from "react";
import { WebsiteTemplate } from "../template";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import { NavList, FooterNavList, AdditonalRoutes } from "../utils";

const CombinedNavList = [...NavList, ...FooterNavList, ...AdditonalRoutes];

// Routes component
export const AppRoutes = () => {
	return (
		<BrowserRouter basename="/">
			<WebsiteTemplate>
				<Switch>
					{CombinedNavList.map((route, index) => (
						<Route key={index} exact path={route.path} component={route.component} />
					))}
				</Switch>
			</WebsiteTemplate>
		</BrowserRouter>
	);
};
