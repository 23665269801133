import React from "react";
import { useThemeStore } from "../../stores";
import { FaSun, FaMoon } from "react-icons/fa";

export const ThemeSelector = () => {
	const { theme, setDarkMode, setLightMode } = useThemeStore();

	return (
		<div className="theme-selector">
			<button
				className={`theme-btn ${theme === "lightmode" ? "active" : ""}`}
				onClick={() => setLightMode()}
			>
				<FaSun size={24} />
			</button>
			<button
				className={`theme-btn ${theme === "darkmode" ? "active" : ""}`}
				onClick={() => setDarkMode()}
			>
				<FaMoon size={24} />
			</button>
		</div>
	);
};
