import React from "react";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemButton,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

export const FAQsAccordion = (props) => {
	const { data } = props;

	return (
		<Accordion className="accodion-style--1" preExpanded="0">
			{data.map((faq, index) => {
				return (
					<AccordionItem key={index} uuid={index}>
						<AccordionItemHeading>
							<AccordionItemButton>{faq.question}</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>
							<p>{faq.answer}</p>
						</AccordionItemPanel>
					</AccordionItem>
				);
			})}
		</Accordion>
	);
};
