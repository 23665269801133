import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FooterNavList, BusinessInformations } from "../../utils";

export const Footer = () => {
	const { pathname } = useLocation();

	return (
		<footer className="footer-area">
			<div className="footer-wrapper">
				<div className="row align-items-end row--0">
					{pathname !== "/kostenrechner" ? (
						<div className="col-lg-6">
							<div className="footer-left">
								<div
									className="inner"
									style={{
										backgroundImage: `url("./assets/images/logo/big-logo.png")`,
										width: "100%",
										backgroundSize: "contain",
										backgroundRepeat: "no-repeat",
										backgroundPositionX: "right",
									}}
								>
									<span>JETZT UNVERBINDLICH </span>
									<h2>
										ANGEBOT <br /> EINHOLEN!
									</h2>
									<Link className="rn-button-style--2" to="/kontakt">
										<span>Zur Kontaktseite</span>
									</Link>
								</div>
							</div>
						</div>
					) : (
						<div className="col-lg-6">
							<div className="footer-left">
								<div
									className="inner"
									style={{
										backgroundImage: `url("./assets/images/logo/big-logo.png")`,
										width: "100%",
										backgroundSize: "contain",
										backgroundRepeat: "no-repeat",
										backgroundPositionX: "right",
									}}
								>
									<span>Wir sind bereit!</span>
									<h2>
										Kontaktieren <br /> Sie Uns!
									</h2>
									<Link className="rn-button-style--2" to="/kontakt">
										<span>Kontakt</span>
									</Link>
								</div>
							</div>
						</div>
					)}
					<div className="col-lg-6">
						<div
							className="footer-right"
							data-black-overlay="6"
							style={{
								backgroundImage: `url("./assets/images/bg/bg-image-8.jpg")`,
							}}
						>
							<div className="row">
								<div className="col-lg-6 col-sm-6 col-12">
									<div className="footer-link">
										<h4>Weiteres</h4>
										<ul className="ft-link">
											{FooterNavList.map((item, index) => (
												<li key={index}>
													<Link to={item.path}>{item.linkText}</Link>
												</li>
											))}
										</ul>
									</div>
								</div>

								<div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
									<div className="footer-link">
										<h4>Kontakt</h4>
										<address>
											{BusinessInformations.Unternehmen}
											<br />
											{BusinessInformations.Strasse}
											<br />
											{BusinessInformations.PLZ} {BusinessInformations.Ort}
											<br />
											<span className="mail">
												<a href={`mailto:${BusinessInformations.Email}`}>
													{BusinessInformations.Email}
												</a>
											</span>
											<br />
											<span className="tel">
												<a href={`tel:${BusinessInformations.Telefon}`}>
													{BusinessInformations.Telefon}
												</a>
											</span>
										</address>
									</div>
								</div>

								<div className="col-lg-12">
									<div className="copyright-text">
										<p>
											{`${
												BusinessInformations.Unternehmen
											} ©${new Date().getFullYear()} All Rights Reserved.`}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
