import React from "react";
import { BusinessInformations } from "../../utils";
import { FaRegArrowAltCircleRight, FaRegEnvelope } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";

import "./TopBar.scss";

export const TopBar = () => {
	return (
		<div className="topbar">
			<div className="hideOnSmallScreens">
				<FaRegArrowAltCircleRight />
				Ihr erfahrener Baupartner für alle Arbeiten im Hoch- und Tiefbau.
			</div>
			<div>
				<FiMapPin />
				{BusinessInformations.Strasse} &nbsp;-&nbsp;
				{BusinessInformations.PLZ} {BusinessInformations.Ort}
			</div>
			<div>
				<FaRegEnvelope />
				<a style={{ color: "#d4d4d4" }} href={`mailto:${BusinessInformations.Email}`}>
					{BusinessInformations.Email}
				</a>
			</div>
		</div>
	);
};
