import React from "react";

export const PageSlider = (props) => {
	const { slides } = props;

	return (
		<div className="slider-wrapper">
			<div className="slider-activation">
				{slides.map((value, index) => {
					const {
						textPosition,
						bgImage,
						category,
						title,
						description,
						buttonText,
						buttonLink,
					} = value;
					return (
						<div
							className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image`}
							style={{
								backgroundImage: `url(${bgImage})`,
							}}
							key={index}
						>
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className={`inner ${textPosition}`}>
											{category ? <span>{category}</span> : ""}
											{title ? <h1 className="title">{title}</h1> : ""}
											{description ? <p className="description">{description}</p> : ""}
											{buttonText ? (
												<div className="slide-btn">
													<a
														className="rn-button-style--2 btn-solid"
														href={`${buttonLink}`}
													>
														{buttonText}
													</a>
												</div>
											) : (
												""
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default PageSlider;
