export const faqPhotovoltaik = [
	{
		question: " Was ist eine Photovoltaikanlage?",
		answer:
			" Eine Photovoltaikanlage ist ein System, das Solarzellen verwendet, um Sonnenlicht in elektrische Energie umzuwandeln. Diese Energie kann dann genutzt werden, um Strom für Ihr Zuhause oder Unternehmen zu erzeugen.",
	},

	{
		question: " Wie funktioniert eine Photovoltaikanlage?",
		answer:
			" Photovoltaikanlagen nutzen Solarzellen, um Photonen aus Sonnenlicht einzufangen und in elektrischen Strom umzuwandeln. Dieser erzeugte Gleichstrom wird dann mithilfe eines Wechselrichters in den für den Haushaltsgebrauch benötigten Wechselstrom umgewandelt.",
	},

	{
		question: " Welche Vorteile bieten Photovoltaikanlagen?",
		answer:
			" Photovoltaikanlagen können dazu beitragen, Ihre Stromrechnung zu senken, die Umweltbelastung zu reduzieren, den Wert Ihrer Immobilie steigern und langfristig Energiekosten einsparen.",
	},

	{
		question: " Wie groß sollte meine Photovoltaikanlage sein?",
		answer:
			" Die Größe Ihrer Anlage hängt von Ihrem Energiebedarf, Ihrem Standort und Ihrem Budget ab. Eine professionelle Bewertung durch einen Solarexperten kann Ihnen bei dieser Entscheidung helfen.",
	},

	{
		question: " Welche Arten von Photovoltaikanlagen gibt es?",
		answer:
			" Es gibt verschiedene Arten von Photovoltaikanlagen, darunter netzgekoppelte Anlagen, netzunabhängige (Insel-)Anlagen und hybride Systeme, die mit anderen Energiequellen kombiniert werden können.",
	},

	{
		question: " Wie viel kostet eine Photovoltaikanlage?",
		answer:
			" Die Kosten einer Photovoltaikanlage variieren je nach Größe, Standort und technischen Anforderungen. Im Allgemeinen reicht der Preis von einigen tausend bis zu mehreren zehntausend Euro.",
	},

	{
		question: " Wie lange dauert die Amortisation einer Photovoltaikanlage?",
		answer:
			" Die Amortisationszeit hängt von den anfänglichen Investitionskosten und den Energieeinsparungen ab. In der Regel liegt sie zwischen 5 und 15 Jahren.",
	},

	{
		question: " Welche Wartung erfordert eine Photovoltaikanlage?",
		answer:
			" Photovoltaikanlagen erfordern nur minimale Wartung, wie die gelegentliche Reinigung der Solarpaneele und die Überprüfung der Leistung durch einen Fachmann.",
	},

	{
		question: " Kann ich Überschussstrom ins Netz einspeisen?",
		answer:
			" Ja, viele Länder ermöglichen die Einspeisung von Überschussstrom ins öffentliche Netz, was zu Vergütungen oder Gutschriften auf Ihrer Stromrechnung führen kann.",
	},

	{
		question: " Benötige ich eine Genehmigung für den Bau einer Photovoltaikanlage?",
		answer:
			" Ja, in den meisten Fällen sind Genehmigungen und behördliche Genehmigungen erforderlich. Ihr Solarexperte kann Ihnen bei diesem Prozess helfen.",
	},

	{
		question: " Welche Förderungen oder Steuervorteile gibt es für Photovoltaikanlagen?",
		answer:
			" Viele Länder bieten finanzielle Anreize wie Steuervergünstigungen oder Zuschüsse für den Kauf von Photovoltaikanlagen. Informieren Sie sich bei Ihrer örtlichen Behörde oder Ihrem Solarexperten.",
	},

	{
		question: " Was passiert an bewölkten Tagen oder nachts mit der Stromproduktion?",
		answer:
			" Photovoltaikanlagen erzeugen weniger Strom bei bewölktem Wetter und nachts überhaupt keinen Strom. In solchen Fällen wird weiterhin Strom aus dem öffentlichen Netz bezogen.",
	},
];
