import React, { Fragment } from "react";
import { PageHelmet, PageSlider, ServiceSlider } from "../../component";

export const TiefbauPage = () => {
	const Leistungen = [
		{
			id: 0,
			title: "Tiefbau",
			description:
				"Unsere Expertise im Tiefbau ermöglicht es uns, komplexe Bauprojekte erfolgreich umzusetzen, sei es im Straßenbau, Kanalbau oder bei Erdarbeiten. Wir bieten umfassende Tiefbauleistungen an, die auf Qualität, Effizienz und Nachhaltigkeit ausgelegt sind. Unser Ziel ist es, sichere und langlebige Infrastrukturen zu schaffen, die den höchsten Anforderungen gerecht werden.",
			image: "./assets/images/service/tiefbau-1.png",
			imageSide: "right",
			itemsTitle: "Tiefbauleistungen",
			items: [
				"Erdarbeiten und Geländeerschließung",
				"Bau von Straßen, Wegen und Plätzen",
				"Pflasterarbeiten",
				"Kanalarbeiten und Entwässerungssysteme",
				"Gründungen und Fundamente für Bauwerke",
				"Bau von Stützwänden und Böschungen",
				"Verlegung von Versorgungsleitungen (Wasser, Gas, Strom)",
				"Bau von Verkehrswegen",
				"Anwendung moderner Baumaschinen und Technologien",
				"Glasfaser- und Telekommunikationsinfrastruktur",
			],
		},
		{
			id: 1,
			title: "Nachhaltiger Tiefbau",
			description:
				"Im Tiefbau legen wir großen Wert auf nachhaltige Techniken und den Schutz der Umwelt. Unsere Projekte werden so geplant, dass sie minimalen Einfluss auf die Natur haben und gleichzeitig höchste Effizienz und Stabilität gewährleisten. Durch den Einsatz moderner Technologien und ökologischer Bauverfahren tragen wir dazu bei, dass Infrastrukturen umweltfreundlich und zukunftsfähig gestaltet werden.",
			image: "./assets/images/service/tiefbau-2.png",
			imageSide: "left",
		},
	];

	const slides = [
		{
			textPosition: "text-right",
			bgImage: "./assets/images/bg/tiefbau.jpg",
			title: "Tiefbau",
			description:
				"Im Tiefbau stehen wir für präzise Ausführung, modernste Technik und nachhaltige Lösungen. Gemeinsam realisieren wir Infrastrukturen, die für die Zukunft gebaut sind.",
		},
	];

	return (
		<Fragment>
			<PageHelmet pageTitle="Eco² Building - Tiefbau" />
			<PageSlider slides={slides} />
			{Leistungen.map((leistung) => {
				return (
					<ServiceSlider
						key={leistung.id}
						title={leistung.title}
						description={leistung.description}
						image={leistung.image}
						imageSide={leistung.imageSide}
						itemsTitle={leistung.itemsTitle}
						items={leistung.items}
					/>
				);
			})}
		</Fragment>
	);
};

export default TiefbauPage;
