import React from "react";
import { useThemeStore } from "../../../stores";
import { textLimiter } from "../../../utils";

import { IoIosBusiness } from "react-icons/io";
import { FaBriefcase, FaCalendarCheck } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";

import "./JobCard.style.scss";

export const JobCard = (props) => {
	const {
		id,
		title = "Titel",
		company = "Eco 2 Building",
		description = "",
		capacity = "Vollzeit",
		location = "Frankfurt am Main",
		releaseDate = new Date(),
		handleClick,
	} = props;

	const { theme } = useThemeStore();

	const darkLogo = "./assets/images/logo/logo-dark.svg";
	const lightLogo = "./assets/images/logo/logo-light.svg";

	const logo = theme === "lightmode" ? darkLogo : lightLogo;
	const textColor = theme === "lightmode" ? "#717173" : "rgba(198,201,216,.75)";

	return (
		<div className="jobcard">
			<div className="jobcard-header">
				<div className="jobcard-header-left">
					<div>
						<img src={logo} alt="Logo" width="80px" />
					</div>
					<div
						className="jobcard-header-left-text"
						style={{
							color: textColor,
						}}
					>
						<div className="title">{title}</div>
						<div className="location">
							<IoIosBusiness size={24} /> {company}
						</div>
					</div>
				</div>
				<div className="jobcard-header-right">
					<button onClick={() => handleClick(id)}>Jetzt Bewerben</button>
				</div>
			</div>
			<div className="divider" />
			<div className="jobcard-body">
				<div className="jobcard-body-header">
					<div>
						<FaBriefcase color={textColor} /> {capacity}
					</div>
					<div>
						<HiOutlineLocationMarker size={16} color={textColor} /> {location}
					</div>
					<div>
						<FaCalendarCheck color={textColor} /> {releaseDate.toLocaleDateString()}
					</div>
				</div>
				<div>
					<p>{textLimiter(description, 200)}</p>
				</div>
			</div>
		</div>
	);
};
