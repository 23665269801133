import { useThemeStore } from "../../../stores";

const theme = useThemeStore.getState().theme;

export const photovoltaikDachform = [
	{
		key: "dachform",
		title: "Satteldach",
		image: `./assets/images/kostenrechner/satteldach-${theme}.png`,
	},
	{
		key: "dachform",
		title: "Flachdach",
		image: `./assets/images/kostenrechner/flachdach-${theme}.png`,
	},
	{
		key: "dachform",
		title: "Pultdach",
		image: `./assets/images/kostenrechner/pultdach-${theme}.png`,
	},
	{
		key: "dachform",
		title: "Andere Dachform",
		image: "./assets/images/kostenrechner/weiteres.png",
	},
];
