import React, { Fragment } from "react";
import { About, Slider, Services, Counter } from "./partials";
import { PageHelmet } from "../../component";

export const StartPage = () => {
	return (
		<Fragment>
			<PageHelmet pageTitle="Eco² Building - Startseite" />
			<Slider />
			<About />
			<Services />
			<Counter />
		</Fragment>
	);
};

export default StartPage;
