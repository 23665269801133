import React from "react";
import { Helmet } from "react-helmet";

export const PageHelmet = (props) => {
	const { pageTitle } = props;

	return (
		<Helmet>
			<title>{pageTitle} </title>
			<meta
				name="description"
				content="Erneuerbare Energie und erstklassige Bauqualität"
			/>
		</Helmet>
	);
};

export default PageHelmet;
